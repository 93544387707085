const authorArchiveContainer = document.querySelector('.archive.author');

if (authorArchiveContainer) {
    const isPaginationClicked = localStorage.getItem('isPaginationClicked');
    const paginationItems = document.querySelectorAll('.page-item');

    if (isPaginationClicked) {
        const scrollTargetIdOffset = document.getElementById('e-single-author__posts').offsetTop;
        const headerHeight = document.getElementById('main-nav').offsetHeight;

        window.scroll(0, scrollTargetIdOffset - (headerHeight / 2));
        localStorage.removeItem('isPaginationClicked')
    }

    paginationItems.forEach((paginationItem) => {
        paginationItem.addEventListener('click', () => {
            localStorage.setItem('isPaginationClicked', true);
        })
    })
}
