import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

const sliderLogos = document.querySelectorAll('.js-slider-logos');
const sliderCards = document.querySelectorAll('.js-slider-cards');
const sliderCategories = document.querySelector('.js-slider-categories');
const sliderGallery = document.querySelector('.js-slider-gallery');
const sliderCarousel = document.querySelectorAll('.js-product-carousel');

if (sliderLogos) {
    sliderLogos.forEach((slider) => {
        const swiperSliderLogos = new Swiper(slider, {
            modules: [Autoplay],
            slidesPerView: 2,
            spaceBetween: 24,
            loop: true,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },
            breakpoints: {
                640: {
                    slidesPerView: 3,
                    spaceBetween: 24,
                },
                1200: {
                    slidesPerView: 5,
                    spaceBetween: 24,
                },
            }, 
        });
    });
}

if (sliderCards) {
    sliderCards.forEach((slider) => {
        let slidesMobile = slider.dataset.slidesMobile;
        let slidesTablet = slider.dataset.slidesTablet;
        let slidesDesktop = slider.dataset.slidesDesktop;
        let sliderSwiper = slider.querySelector('.js-slider-cards-slider');
        let sliderPrev = slider.querySelector('.js-slider-cards-nav-prev');
        let sliderNext = slider.querySelector('.js-slider-cards-nav-next');
        const swiperSliderCards = new Swiper(sliderSwiper, {
            modules: [Navigation],
            slidesPerView: slidesMobile ? slidesMobile : 1,
            spaceBetween: 16,
            navigation: {
                nextEl: sliderNext,
                prevEl: sliderPrev,
            },
            breakpoints: {
                640: {
                    slidesPerView: slidesTablet ? slidesTablet : 2,
                    spaceBetween: 24,
                },
                1200: {
                    slidesPerView: slidesDesktop ? slidesDesktop : 3,
                    spaceBetween: 24,
                },
            }, 
        });
    });
}

if (sliderCategories) {
    let scrollbar = sliderCategories.querySelector('.swiper-scrollbar');
    let swiperCategories = new Swiper(sliderCategories, {
        direction: "horizontal",
        slidesPerView: "auto",
        freeMode: true,
        scrollbar: {
            el: scrollbar,
        },
        mousewheel: true,
    });
}

if (sliderGallery) {
    let sliderPrev = sliderGallery.querySelector('.gallery-button-prev');
    let sliderNext = sliderGallery.querySelector('.gallery-button-next');

    let swiperGallery = new Swiper(sliderGallery, {
        modules: [Navigation],
        navigation: {
            nextEl: sliderNext,
            prevEl: sliderPrev,
        },
        direction: "horizontal",
        spaceBetween: 16,
        slidesPerView: 1.05,
        breakpoints: {
            1025: {
                slidesPerView: 1,
                spaceBetween: 40,
            },
        },
    });

    swiperGallery.on('slideChangeTransitionStart', updateCurrentGalleryCount);
}


function updateCurrentGalleryCount() {
    let currentSlide = sliderGallery.querySelector('.swiper-slide-active').getAttribute('aria-label');
    let currentCounter = document.querySelector('.counter__current');
    currentCounter.innerHTML = currentSlide;
}

if (sliderCarousel) {
    sliderCarousel.forEach((slider) => {
        let sliderSwiper = slider.querySelector('.js-slider-product-carousel');
        let sliderPrev = slider.querySelector('.js-slider-product-carousel-prev');
        let sliderNext = slider.querySelector('.js-slider-product-carousel-next');
        let sliderPagination = slider.querySelector('.js-slider-product-pagination');
        const swipersliderCarousel = new Swiper(sliderSwiper, {
            modules: [Navigation, Pagination],
            slidesPerView: 1,
            spaceBetween: 100,
            loop: true,
            autoHeight: true,
            navigation: {
                nextEl: sliderNext,
                prevEl: sliderPrev,
            },
            pagination: {
                el: sliderPagination,
            },
            breakpoints: {
                992: {
                    autoHeight: false,
                    spaceBetween: 32,
                }
            }
        });
    });
}