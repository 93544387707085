const geolocate = document.querySelectorAll('.e-geolocate');
const geolocateCta = document.querySelectorAll('.e-geolocate-cta');
const geolocateResults = document.querySelectorAll(
  '.e-geolocate-cta__partners'
);

const geolocateBannerUnset = document.querySelectorAll('.navbar__geolocation-unset');
const geolocateBanner = document.getElementById('navbar__geolocation-unset');

const geolocateOptions = {
  maximumAge: 60000,
  timeout: 5000,
  enableHighAccuracy: true,
};

if (geolocate.length) {
  function clearResults() {
    if (geolocateResults) {
      geolocateResults.forEach((item) => {
        item.innerHTML = '';
      });
    }
  }
  
  function getPartnerForPostcode(postcode) {
    let nonce = geolocate[0].dataset.nonce;
  
    console.log('Lookup zip: ' + postcode);
    jQuery.ajax({
      type: 'post',
      dataType: 'json',
      url: enerixAjax.ajaxurl,
      data: {
        action: 'get_partner_by_postcode',
        postcode: postcode,
        nonce: nonce,
      },
      success: function (response) {
        clearResults();
        let resultHtml = '';
        let bannerResultHtml = '';
        let bannerResultLink = '';

        if (response.length) {
          if(geolocateBannerUnset) {
            geolocateBannerUnset.forEach((banner) => {
                banner.classList.remove('navbar__geolocation-unset');
                banner.classList.add('navbar__geolocation-set');
            })
          }
        
          response.forEach((item) => {
            let bannerHtml = `
            <div class="text-dark m-0 d-flex flex-column flex-md-row">
                ${item.banner_title}
                <div class="d-flex flex-row align-items-center mt-1 mt-md-0">
                    <a href="${item.location_url}">
                      <img src="${item.location_thumbnail}" class="navbar__geolocation-image rounded-circle object-fit-cover ms-0 ms-md-2">
                    </a>
                    <div class="d-flex flex-column flex-md-row ms-2 ms-md-0">
                        <a href="${item.location_url}">
                          <p class="m-0 ms-0 ms-md-2 fw-600 text-decoration-none">${item.location_title}</p>
                        </a>
                        <a href="tel:${item.location_phone}" class="m-0 ms-0 ms-md-2 text-body text-decoration-none">${item.location_phone}</a>
                    </div>
                </div>
            </div>
            <a href="${item.location_url}" class="navbar__geolocation-button text-primary m-0 mt-1 mt-md-0 fw-600 text-decoration-underline w-md-auto d-flex align-items-center">
                zum Standort
                <span class="ms-1">
                <svg class="e-icon e-icon-arrow-right e-icon--size-normal e-icon--color-primary"><use xlink:href="/wp-content/themes/enerix/icons/icons.svg#icon-arrow-right"></use></svg>
                </span>
            </a>
            `;
            bannerResultHtml += bannerHtml;
            bannerResultLink += item.location_url;


            let partnerHtml = `
            <div class="d-flex align-items-center mt-auto mb-3">
                <img src="${item.location_thumbnail}" class="rounded-circle object-fit-cover me-2" width="48" height="48">
                <div class="fs-6">
                    <span class="d-block fw-600 fs-5">${item.location_head.team_member_name}</span>
                    <span class="d-block">${item.location_title}</span>
                </div>
            </div>
            <div class="d-flex mb-1">
                <svg class="e-icon e-icon-phone e-icon--size-normal e-icon--color-primary "><use xlink:href="/wp-content/themes/enerix/icons/icons.svg#icon-phone"></use></svg>
                <p class="fs-6 ms-2 mb-0 fw-600">
                    <a class="text-decoration-none" href="tel:${item.location_phone}">
                    ${item.location_phone}
                    </a>
                </p>
            </div>
            <a href="${item.location_url}" class="d-flex align-items-center justify-content-center btn btn-white mt-3 mb-0 w-100">
              <span class="me-1 fw-600">${item.location_title}</span>
              <svg class="e-icon e-icon-arrow-right e-icon--size-normal e-icon--color-dark-gray"><use xlink:href="/wp-content/themes/enerix/icons/icons.svg#icon-arrow-right"></use></svg>
            </a>          
            `;
            resultHtml += partnerHtml;

          });
  
          if (geolocateResults) {
            geolocateResults.forEach((item) => {
              console.log(item);
              item.innerHTML = resultHtml;
            });
            geolocateCta.forEach((item) => {
              item.classList.add('e-geolocate-cta--partner-visible');
            });
          }

          if(geolocateBanner) {
            geolocateBanner.innerHTML = bannerResultHtml;
            geolocateBanner.setAttribute('href', bannerResultLink);
          }
        } else {
          geolocateCta.forEach((item) => {
            item.classList.remove('e-geolocate-cta--partner-visible');
          });
          console.log('No partner found for postcode: ' + postcode);
        }
      },
    });
  }
  
  async function checkGeolocationPermission() {
    const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });
    return permissionStatus.state;
  }
  


  function checkGeolocation() {
    
    navigator.geolocation.getCurrentPosition(
    function (pos) {
        fetch(
        'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
            pos.coords.latitude +
            ',' +
            pos.coords.longitude +
            '&location_type=ROOFTOP&key=AIzaSyBExjOtwNRpPAQ7fyMOijvRRHdheS7A_JU'
        )
        .then((response) => response.json())
        .then((json) => {
            if (json.results) {
                let address = json.results[0];
                let postcode = '';
                if (address.address_components) {
                    address.address_components.forEach((component) => {
                    if (component.types[0] == 'postal_code') {
                        postcode = component.long_name;
                    }
                    });
                }

                if (postcode) {
                    getPartnerForPostcode(postcode);
                } else {
                    geolocateCta.classList.remove(
                    'e-geolocate-cta--partner-visible'
                    );
                }

                if(document.cookie.indexOf('postcode=') == -1) {
                    var date = new Date();
                    date.setTime(date.getTime() + (48 * 3600 * 1000));
                    document.cookie = "postcode=" + (postcode || "") + "; expires=" + date.toUTCString() + "; path=/";
                }
            }
        });
    },
    function (error) {
        if (error.code == error.PERMISSION_DENIED) {
            document.cookie = "postcode=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
            var date = new Date();
            date.setTime(date.getTime() + (48 * 3600 * 1000));
            document.cookie = "geolocationDenied=true; expires=" + date.toUTCString() + "; path=/";
        }
    },
    geolocateOptions
    );
  }

  window.setTimeout(async () => {
    const currentPermissionState = await checkGeolocationPermission();
    
    setTimeout(() => {
        if(currentPermissionState == 'prompt') {
            if(document.cookie.indexOf('postcode') == -1){ 
                if(document.cookie.indexOf('geolocationDenied') == -1) {
                    checkGeolocation();
                }
            }
        }
        else if(currentPermissionState == 'granted'){
            if(document.cookie.indexOf('postcode') == -1){
                checkGeolocation();
                document.cookie = "geolocationDenied=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
            }
        }
        else {
            document.cookie = "postcode=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
        }
    }, 2000);
  }, 3000);
}
