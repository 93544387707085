const lazyLoadParents = document.querySelectorAll('.js-lazy-load-scripts');

function isScrolledIntoView(el) {
  var rect = el.getBoundingClientRect();
  var elemTop = rect.top;
  var elemBottom = rect.bottom;
  var isVisible = elemTop < window.innerHeight && elemBottom >= 0;
  return isVisible;
}

function lazyLoadScript(parent) {
  const lazyLoadScripts = parent.querySelectorAll(
    '.js-lazy-load-script'
  ); 
  if (isScrolledIntoView(parent)) {
    if (lazyLoadScripts) {
      lazyLoadScripts.forEach((lazyLoadScript) => {
        if (!lazyLoadScript.getAttribute('src')) {
          lazyLoadScript.setAttribute(
            'src',
            lazyLoadScript.getAttribute('data-src')
          );
        }
      });
    }
  } 
}

if (lazyLoadParents) {
  lazyLoadParents.forEach((lazyLoadParent) => { 
    window.addEventListener('scroll', () => {
      lazyLoadScript(lazyLoadParent);
    });
    window.addEventListener('resize', () => {
      lazyLoadScript(lazyLoadParent);
    });
    window.addEventListener('load', () => {
      lazyLoadScript(lazyLoadParent);
    });
  });
}
