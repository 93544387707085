const heroScrollArrow = document.querySelector('.hero__scroll');

if(heroScrollArrow) {
    heroScrollArrow.addEventListener('click', (event) => {
        event.preventDefault();
    
        const scrollTarget = event.target.dataset.scroll;
        const scrollTargetIdOffset = document.getElementById(scrollTarget).offsetTop;
        const headerHeight = document.getElementById('main-nav').offsetHeight;
    
        window.scroll(0, scrollTargetIdOffset - headerHeight);
    });
}
