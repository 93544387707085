/* Manage open and close for filters dropdowns */
const filters = document.querySelectorAll('.e-filters__filter');
const dropdowns = document.querySelectorAll('.e-filters__filter-dropdown');

if (filters) {
    filters.forEach((filter) => {
        filter.querySelector('.e-filters__title').addEventListener('click', (event) => {
            const clickedFilterDropdown = filter.querySelector('.e-filters__filter-dropdown');
            const clickedClearButton = filter.querySelector('.e-filters__clear');
            const filterRelatedCheckboxes = filter.querySelectorAll('.e-filters__filter-dropdown .checkbox');

            if (event.target == clickedClearButton) {
                filterRelatedCheckboxes.forEach((filterRelatedCheckbox) => {
                    filterRelatedCheckbox.checked = false;
                })

                return;
            }

            if (clickedFilterDropdown.classList.contains('hide')) {
                dropdowns.forEach((dropdown) => {
                    dropdown.classList.add('hide');
                });

                clickedFilterDropdown.classList.remove('hide');
            }
            else {
                clickedFilterDropdown.classList.add('hide');
            }
        });
    });
}

/* Toggle filters visibility */
const filtersFormToggle = document.querySelector('.e-filters__toggle');
const filtersForm = document.querySelector('.e-filters__form');

if (filtersFormToggle) {
    filtersFormToggle.addEventListener('click', () => {


        if (filtersFormToggle.classList.contains('opened-desktop')) {
            filtersFormToggle.classList.remove('opened-desktop');
            filtersForm.classList.remove('opened-desktop');
        }
        else if (filtersFormToggle.classList.contains('closed')) {
            filtersFormToggle.classList.add('opened');
            filtersFormToggle.classList.remove('closed');

            filtersForm.classList.remove('hide');
        }
        else {
            filtersFormToggle.classList.add('closed');
            filtersFormToggle.classList.remove('opened');

            filtersForm.classList.add('hide');
        }
    });
}

/* Close all dropdowns when clicked outside container */
document.addEventListener('click', (event) => {
    const filtersContainer = document.querySelector('.e-filters__form');
    const applyFiltersButton = document.querySelector('.e-filters__apply-filter')

    if (filtersContainer && applyFiltersButton) {
        if ((event.target !== filtersContainer && !filtersContainer.contains(event.target)) || event.target == applyFiltersButton) {
            dropdowns.forEach((dropdown) => {
                dropdown.classList.add('hide');
            });
        }
    }
})

/* Update count indicator for mobile - on checkbox click */
let filtersCheckboxes = document.querySelectorAll('.e-filters__filter-field');

if (filtersCheckboxes) {
    filtersCheckboxes.forEach((filtersCheckbox) => {
        filtersCheckbox.addEventListener('click', () => {
            const checkboxParent = filtersCheckbox.closest('.e-filters__filter');
            const checkedCheckboxes = checkboxParent.querySelectorAll('input:checked');
            const filtersIndicator = checkboxParent.querySelector('.e-filters__title-indicator');

            filtersIndicator.innerHTML = checkedCheckboxes.length;

            if (checkedCheckboxes.length < 1){
                filtersIndicator.classList.add('hide');
            }
            else {
                filtersIndicator.classList.remove('hide');
            }
        });
    })
}