const glossaryListTerms = document.querySelectorAll('.js-glossary-term');
const glossaryListTermsGroups = document.querySelectorAll('.js-glossary-terms-group');
const glossaryFilterField = document.querySelector('.js-glossary-filter');

function filterGlossaryTerms() {
    resetAllTerms();
    let search = glossaryFilterField.value;
    let searchLowercase = search.toLowerCase();
    glossaryListTerms.forEach((term) => {
        let termText = term.innerText.toLowerCase();
        if (!termText.includes(searchLowercase)) term.classList.add('filtered');
    });
    filterGroups();
}

function resetAllTerms() {
    glossaryListTerms.forEach((term) => {
        term.classList.remove('filtered');
    });
    glossaryListTermsGroups.forEach((termGroup) => {
        termGroup.classList.remove('filtered');
    });
}

function filterGroups() {
    glossaryListTermsGroups.forEach((termGroup) => {
        let groupTerms = termGroup.querySelectorAll('.js-glossary-term');
        let groupFilteredTerms = termGroup.querySelectorAll('.filtered');
        if (groupTerms.length > 0 && groupTerms.length == groupFilteredTerms.length) termGroup.classList.add('filtered');
    });
}

if (glossaryFilterField) {
    glossaryFilterField.addEventListener('keyup', (event) => { 
        filterGlossaryTerms();
    });
}

