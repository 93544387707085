var navigation = document.getElementById("main-nav");

if (navigation) {
	window.onscroll = function () { scrollHeader() };

	var navigationOffset = navigation ? navigation.offsetTop : 0

	const scrollHeader = () => {
		if (window.pageYOffset > navigationOffset) {
			navigation.classList.add("scrolled");
		} else {
			navigation.classList.remove("scrolled");
		}
	}
}
